// 基础数据
import request from '@/utils/util.js'

/**
 * @description 园区
 */
// 新增、编辑园区
export function saveAreaDataAPI(data) {
	return request.post('/home_config/area_save', data)
}

// 获取园区数据
export function getParkDataAPI(data) {
	return request.get('/home_config/area_list', data)
}

// 删除园区
export function delParkAPI(data) {
	return request.post('/home_config/area_delete', data)
}

// 所有园区 --无分页
export function getAllParkDataAPI() {
	return request.get('/home_config/area_all')
}

/**
 * @description 楼栋 
 */
// 新增、编辑楼栋
export function saveBuildingAPI(data) {
	return request.post('/home_config/building_save', data)
}

// 获取楼栋数据
export function getBuildingDataAPI(data) {
	return request.get('/home_config/building_list', data)
}

// 删除楼栋
export function delBuildingAPI(data) {
	return request.post('/home_config/building_delete', data)
}

// 所有楼栋 --无分页
export function getAllBuildingDataAPI(data) {
	return request.get('/home_config/building_all', data)
}


/**
 * @description 楼层
 */
// 新增、编辑楼层
export function saveFloorAPI(data) {
	return request.post('/home_config/floor_save', data)
}

// 获取楼层数据
export function getFloorDataAPI(data) {
	return request.get('/home_config/floor_list', data)
}

// 删除楼层
export function delFloorAPI(data) {
	return request.post('/home_config/floor_delete', data)
}

// 所有楼层 --无分页
export function getAllFloorDataAPI(data) {
	return request.get('/home_config/floor_all', data)
}


/**
 * @description 房间
 */
// 新增、编辑房间
export function saveRoomAPI(data) {
	return request.post('/home_config/room_save', data)
}

// 获取房间数据
export function getRoomDataAPI(data) {
	return request.get('/home_config/room_list', data)
}

// 所有房间 --无分页
export function getAllRoomDataAPI(data) {
	return request.get('/home_config/room_all', data)
}


/**
 * @description 床位
 */
// 新增、编辑床位
export function saveBedAPI(data) {
	return request.post('/home_config/bed_save', data)
}

// 获取床位数据
export function getBedDataAPI(data) {
	return request.get('/home_config/bed_list', data)
}


// 获取园区、楼栋、楼层、房间、床位的级联数据
export function getHouseCascaderDataAPI(data) {
	return request.post('/home_config/area_building_room_bed', data)
}